<template>
  <AContainer class="b-about-amgen is-fluid is-paddingless mb-6 p-3">
    <ASectionTitle>
      <template v-slot:icon>
        <img src="../../assets/info_v2.svg" alt="Ícone sobre a Amgen" />
      </template>
      <template v-slot:title>
        <span style="color: #FFFFFF !important;">
          Sobre a Amgen
        </span>
      </template>
    </ASectionTitle>
    <div class="o-about has-text-centered mt-5 pb-6 pl-4 pr-4 pt-5">
      <ALogoAmgen class="mb-4 is-hidden-tablet" black />
      <ALogoAmgen class="mb-4 is-hidden-mobile" white />
      <MVideo class="is-inline-block">
        <template v-slot:playButton>
          <img
            class="play"
            src="../../assets/play-button.svg"
            alt="Ícone Play"
          />
        </template>
        <source
          src="../../assets/amgen-a-procura-das-respostas-escondidas.mp4"
          preload="none"
          type="video/mp4"
        />
      </MVideo>
      <AContent class="has-text-left mt-6">
        <p style="text-align: center;">
          A Amgen<sup>®</sup> é uma das líderes mundiais em biotecnologia e
          baseia sua atuação em valores profundamente enraizados na ciência e na
          inovação. Utilizamos ciência e tecnologia de ponta para estudar os
          mais sutis mecanismos biológicos em busca de
          <strong
            >terapias que melhorem a vida de quem sofre com doenças
            graves</strong
          >.
        </p>
        <p style="text-align: center;">
          Temos orgulho do que conquistamos e sabemos que o envelhecimento da
          população global exigirá novos tratamentos para doenças como câncer e
          doenças cardíacas, que ceifam milhões de vidas e custam à sociedade
          centenas de bilhões de reais a cada ano<sup>1</sup>. O espírito
          pioneiro da Amgen<sup>®</sup>, uma marca registrada por quatro
          décadas, nos dá a confiança de que podemos desempenhar um papel cada
          vez mais importante no ecossistema global de saúde e populacional.
        </p>
      </AContent>
    </div>
  </AContainer>
</template>

<script>
import AContainer from "@/components/atoms/Container.vue";
import AContent from "@/components/atoms/Content.vue";
import ALogoAmgen from "@/components/atoms/LogoAmgen.vue";
import ASectionTitle from "@/components/molecules/SectionTitle.vue";
import MVideo from "@/components/molecules/Video.vue";

export default {
  components: {
    AContainer,
    AContent,
    ASectionTitle,
    ALogoAmgen,
    MVideo
  },
  name: "OAboutAmgen"
};
</script>

<style lang="scss">
.b-about-amgen {
  .o-about {
    background-color: $white;
    color: #ffffff;
  }

  .a-logo-amgen {
    width: 147px;
  }
}

@include media(">=tablet") {
  .b-about-amgen {
    .o-about {
      background-color: initial;
      color: #ffffff;
    }
  }
}

.t-about .o-about {
  background-color: #5c56a2 !important;
  border: 2px solid #ffffff40;
  border-radius: 10px;
}
</style>
